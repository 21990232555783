import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import axios from "axios";

import duckImage from "../img/1 (7).gif";
import imgSdgsdg from "../img/sdgsdg 1.png";
import imgFriends from "../img/friends 1.png";
import imgCoins from "../img/coins 1.png";
import imgDuck from "../img/duck 1.png";

import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Navigation, Pagination } from "swiper/modules";

import { useSelector, useDispatch } from "react-redux";

const Main = ({ telegramId }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.currentUser.balance);
  const level = useSelector((state) => state.currentUser.level);
  const duckImg = useSelector((state) => state.currentUser.duck);
  const hardtap = useSelector((state) => state.boosters.hardtap);
  const speedtap = useSelector((state) => state.boosters.speedtap);
  const autotap = useSelector((state) => state.boosters.autotap);

  const [lineW, setLineW] = useState(0);
  const [userName, setUserName] = useState("");

  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
  };

  const [coins, setCoins] = useState([]);

  const createCoin = (amount) => {
    const coinId = new Date().getTime();
    const randomX = Math.random() * 100;
    const randomY = Math.random() * 100;
    setCoins((prevCoins) => [
      ...prevCoins,
      { id: coinId, amount, x: randomX, y: randomY },
    ]);

    setTimeout(() => {
      setCoins((prevCoins) => prevCoins.filter((coin) => coin.id !== coinId));
    }, 1000); // Duration should match the coin-up animation duration
  };

  const changeLevel = async () => {
    try {
      const response = await fetch(`https://duckducktap.xyz:3005/api/user/add-level`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ telegramId: telegramId, newLevel: level + 1 }),
      });
      dispatch({
        type: "edit-level",
        payload: {
          level: level + 1,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    switch (level) {
      case 1:
        if (balance >= 1000) {
          changeLevel();
        }
        break;
      case 2:
        if (balance >= 3000) {
          changeLevel();
        }
        break;
      case 3:
        if (balance >= 5000) {
          changeLevel();
        }
        break;
      case 4:
        if (balance >= 10000) {
          changeLevel();
        }
        break;
    }
  }, [balance]);

  const handleSlideClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    switch (level) {
      case 1:
        setLineW((balance / 1000) * 100);
        break;
      case 2:
        setLineW((balance / 3000) * 100);
        break;
      case 3:
        setLineW((balance / 5000) * 100);
        break;
      case 4:
        setLineW((balance / 10000) * 100);
        break;
    }
  }, [balance]);

  const addBalance = async () => {
    let amount = level;
    if (hardtap) {
      amount = 2 * level;
    }
    try {
      const response = await fetch(
        "https://duckducktap.xyz:3005/api/user/add-balance",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            telegramId: telegramId,
            newBalance: balance + amount,
          }),
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    setUserName(tg.initDataUnsafe.user.first_name)
  }, [])

  return (
    <div className="game">
      <div className="header">
        <p className="user-name">Hi {userName}👋🏻</p>
        <div className="user-score">
          <img src={duckImage} alt="" width="20px" height="20px" />
          <span>{balance}</span>
        </div>
      </div>
      <div style={{ position: "relative" }}>
        <div
          className={`the-duck ${isAnimating ? "scale-down" : ""}`}
          style={{ backgroundImage: `url('1 (${level}).gif')` }}
          onClick={() => {
            let amount = level;
            if (hardtap) {
              amount = 2 * level;
            }
            if (speedtap) {
              addBalance();
              dispatch({
                type: "edit-balance",
                payload: {
                  balance: balance + amount,
                },
              });
              createCoin(amount);
            } else {
              setTimeout(() => {
                addBalance();
                dispatch({
                  type: "edit-balance",
                  payload: {
                    balance: balance + amount,
                  },
                });
                createCoin(amount);
              }, "1000");
            }
            // Trigger the click animation
            setIsAnimating(true);
            setTimeout(() => {
              setIsAnimating(false);
            }, 500);
          }}
        ></div>
        {coins.map((coin) => (
          <div
            key={coin.id}
            className="coin"
            style={{ left: `${coin.x}%`, top: `${coin.y}%` }}
          >
            +{coin.amount}
          </div>
        ))}
      </div>
      <div className="lvl-data">
        <p className="lvl">{`Lvl ${level}`}</p>
        <p className="more">More...</p>
      </div>
      <div className="score-line">
        <div className="line" style={{ width: `${lineW}%` }}></div>
      </div>
      <div className="blocks swiper-container">
        <div className="blocks-panel swiper-wrapper">
          <Swiper
            spaceBetween={5}
            slidesPerView={1}
            loop={true}
            onSlideChange={handleSlideChange}
          >
            <SwiperSlide>
              <div
                className="swiper-slide"
                onClick={() => handleSlideClick("/boosters")}
              >
                <div className="block">
                  <img src={imgSdgsdg} alt="Boosters" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="swiper-slide"
                onClick={() => handleSlideClick("/friends")}
              >
                <div className="block">
                  <img src={imgFriends} alt="Friends" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="swiper-slide"
                onClick={() => handleSlideClick("/coins")}
              >
                <div className="block">
                  <img src={imgCoins} alt="Coins" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="swiper-slide"
                onClick={() => handleSlideClick("/ducks")}
              >
                <div className="block">
                  <img src={imgDuck} alt="Duck" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="swiper-lines">
          <div
            className="swiper-line"
            style={
              activeIndex === 0 ? { background: "var(--main-bg-color)" } : null
            }
          ></div>
          <div
            style={
              activeIndex === 1 ? { background: "var(--main-bg-color)" } : null
            }
            className="swiper-line"
          ></div>
          <div
            style={
              activeIndex === 2 ? { background: "var(--main-bg-color)" } : null
            }
            className="swiper-line"
          ></div>
          <div
            style={
              activeIndex === 3 ? { background: "var(--main-bg-color)" } : null
            }
            className="swiper-line"
          ></div>
        </div>
      </div>
      <div>
        <p className="res">© All rights reserved</p>
      </div>
    </div>
  );
};

export default Main;
