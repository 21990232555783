import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./components/Main";
import Coins from "./components/Coins";
import Ducks from "./components/Ducks";
import Boosters from "./components/Boosters";
import Friends from "./components/Friends";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

function App() {
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.currentUser.balance);
  const level = useSelector((state) => state.currentUser.level);
  const autotap = useSelector((state) => state.boosters.autotap);
  const speedtap = useSelector((state) => state.boosters.speedtap);
  const hardtap = useSelector((state) => state.boosters.hardtap);
  const refCode = useSelector((state) => state.currentUser.refCode);

  const tg = window.Telegram.WebApp;
  const [telegramId, setTelegramId] = useState(
    String(tg.initDataUnsafe.user.id)
  );

  useEffect(() => {
    const createUser = async (telegramId) => {
      const queryParams = new URLSearchParams(window.location.search);
      const paramValue = queryParams.get("code");
      try {
        const response = await fetch(`https://duckducktap.xyz:3005/api/user/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ telegramId, referral: Number(paramValue) }),
        });
        const data = await response.json();
        dispatch({
          type: "add-data",
          payload: {
            balance: data.balance,
            level: data.level,
            duck: `1 (${data.level}).gif`,
            followedTg: data.followedTg,
            followedX: data.followedX,
            refCode: data.refCode,
          },
        });
      } catch (error) {
        console.error(error);
      }
    };
    const getData = async () => {
      try {
        const response = await fetch("https://duckducktap.xyz:3005/api/user/get-one", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ telegramId }),
        });
        const data = await response.json();
        if (data.success) {
          dispatch({
            type: "add-data",
            payload: {
              balance: data.balance,
              level: data.level,
              duck: `1 (${data.level}).gif`,
              followedTg: data.followedTg,
              followedX: data.followedX,
            },
          });
        } else {
          createUser(telegramId);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  const onHardTap = async () => {
    if (!hardtap) {
      try {
        const response = await fetch(
          "https://duckducktap.xyz:3005/api/user/add-balance",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              telegramId: telegramId,
              newBalance: balance - 100,
            }),
          }
        );
      } catch (error) {
        console.log(error);
      }
      dispatch({
        type: "edit-balance",
        payload: {
          balance: balance - 100,
        },
      });
      dispatch({
        type: "hardtap",
        payload: {
          hardtap: true,
        },
      });
      setTimeout(() => {
        dispatch({
          type: "hardtap",
          payload: {
            hardtap: false,
          },
        });
      }, 60000);
    }
  };
  const onSpeedTap = async () => {
    if (!speedtap) {
      try {
        const response = await fetch(
          "https://duckducktap.xyz:3005/api/user/add-balance",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              telegramId: telegramId,
              newBalance: balance - 200,
            }),
          }
        );
      } catch (error) {
        console.log(error);
      }
      dispatch({
        type: "edit-balance",
        payload: {
          balance: balance - 200,
        },
      });
      dispatch({
        type: "speedtap",
        payload: {
          speedtap: true,
        },
      });
      setTimeout(() => {
        dispatch({
          type: "speedtap",
          payload: {
            speedtap: false,
          },
        });
      }, 60000);
    }
  };
  const onAutoTap = async () => {
    if (!autotap) {
      try {
        const response = await fetch(
          "https://duckducktap.xyz:3005/api/user/add-balance",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              telegramId: telegramId,
              newBalance: balance - 500,
            }),
          }
        );
      } catch (error) {
        console.log(error);
      }
      dispatch({
        type: "edit-balance",
        payload: {
          balance: balance - 500,
        },
      });
      dispatch({
        type: "autotap",
        payload: {
          autotap: true,
        },
      });
      setTimeout(() => {
        dispatch({
          type: "autotap",
          payload: {
            autotap: false,
          },
        });
      }, 120000);
    }
  };
  useEffect(() => {
    if (autotap) {
      const interval = setInterval(async () => {
        dispatch({
          type: "edit-balance",
          payload: {
            balance: balance + 4,
          },
        });
        try {
          const response = await fetch(
            "https://duckducktap.xyz:3005/api/user/add-balance",
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                telegramId: telegramId,
                newBalance: balance + 4,
              }),
            }
          );
        } catch (error) {
          console.log(error);
        }
      }, 500);
      return () => clearInterval(interval);
    }
  }, [autotap, balance, level, dispatch]);
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Main telegramId={telegramId} />} />
          <Route path="/coins" element={<Coins />} />
          <Route path="/ducks" element={<Ducks />} />
          <Route
            path="/boosters"
            element={
              <Boosters
                onAutoTap={onAutoTap}
                onHardTap={onHardTap}
                onSpeedTap={onSpeedTap}
              />
            }
          />
          <Route path="/friends" element={<Friends telegramId={telegramId} />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
