import React from "react";
import imgGif from "../img/1 (7).gif";
import imgOrange from "../img/orange.png";
import imgYellowBg from "../img/yellow_bg.png";
import imgGroup29 from "../img/Group 29.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const Boosters = ({onAutoTap, onHardTap, onSpeedTap}) => {
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.currentUser.balance);
  const hardtap = useSelector((state) => state.boosters.hardtap);
  const speedtap = useSelector((state) => state.boosters.speedtap);
  const autotap = useSelector((state) => state.boosters.autotap);
  const navigate = useNavigate();
  useEffect(() => {
    if (window.Telegram) {
      const tg = window.Telegram.WebApp;

      tg.ready();

      tg.BackButton.show();
      tg.BackButton.onClick(() => {
        tg.BackButton.hide();
        navigate("/");
      });
    }
  }, [navigate]);
  return (
    <div className="game">
      <div className="balance">
        <div style={{ width: "100%" }}>
          <p className="balance-text">Your balance</p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              justifyContent: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <img
              style={{ position: "relative" }}
              width="37px"
              height="33px"
              src={imgGif}
              alt=""
            />
            <span className="balance-score">{balance}</span>
          </div>
        </div>
        <img src={imgOrange} alt="" className="img_1" />
        <img src={imgYellowBg} alt="" />
      </div>
      <p className="page-name">Boosters</p>
      <div className="elements">
        <div className="ele" onClick={() => onHardTap()}>
          <div className="ele-box">
            <svg
              width="39"
              height="32"
              viewBox="0 0 39 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.53099 9.85636C7.70856 8.5149 8.39613 7.32324 9.46913 6.50103C10.362 5.81683 11.4266 5.45411 12.551 5.45411C14.139 5.45411 15.6033 6.17618 16.5694 7.43517L18.3738 9.78735C19.1085 9.37078 19.9324 9.15197 20.7891 9.13935C21.9849 6.77622 21.5987 3.81726 19.6235 1.84462C17.1703 -0.609393 13.1956 -0.613601 10.734 1.82695C8.2741 -0.613601 4.30021 -0.610235 1.84367 1.84462C-0.614556 4.30201 -0.614556 8.29189 1.84367 10.7518C1.96485 10.8705 7.80114 16.6815 9.9505 18.8216C10.3848 19.2533 11.0849 19.2533 11.5183 18.8216C11.6875 18.6533 11.887 18.4538 12.0973 18.2434L8.53414 13.5997C7.71193 12.5275 7.3551 11.1978 7.53099 9.85636Z"
                fill="#FFD527"
              />
              <path
                d="M38.4763 19.8802L31.8549 11.2491C31.3541 10.5977 30.6009 10.2552 29.8385 10.2552C29.2982 10.2552 28.7554 10.426 28.2934 10.7803C27.9845 11.0176 27.7447 11.3122 27.578 11.6387C27.0781 11.0463 26.3611 10.7366 25.6348 10.7366C25.0946 10.7366 24.5517 10.9074 24.0914 11.2617C23.5275 11.6943 23.196 12.317 23.1152 12.9701L22.872 12.6528C22.3721 12.0014 21.6197 11.6589 20.8564 11.6589C20.317 11.6589 19.7741 11.8298 19.3138 12.1832C18.75 12.6166 18.4167 13.2402 18.3393 13.8908L14.564 8.9701C14.0641 8.31957 13.3109 7.97705 12.5484 7.97705C12.0081 7.97705 11.4653 8.14789 11.0033 8.50219C9.8916 9.35554 9.68205 10.9503 10.5346 12.0612L19.9938 24.3919L18.2417 23.8928C18.0102 23.828 17.7754 23.796 17.5465 23.796C16.4415 23.796 15.4249 24.5223 15.1068 25.6407C14.7231 26.9898 15.5057 28.3935 16.8547 28.7773L25.9849 31.3744C26.2138 31.4392 26.4486 31.4711 26.6775 31.4711C27.2212 31.4711 27.748 31.2961 28.1848 30.9746L38.0059 23.4384C39.1193 22.5859 39.3297 20.9919 38.4763 19.8802Z"
                fill="#CF9F68"
              />
            </svg>
          </div>
          <div>
            <p>Hardtap</p>
            <button disabled={hardtap} className="buy-btn">
              <img width="17px" height="16px" src={imgGif} alt="" />
              100
            </button>
          </div>
          <p className="ques">?</p>
        </div>

        <div className="ele" onClick={() => onSpeedTap()}>
          <div className="ele-box">
            <img src={imgGroup29} alt="" />
          </div>
          <div>
            <p>Speedtap</p>
            <button disabled={speedtap} className="buy-btn">
              <img width="17px" height="16px" src={imgGif} alt="" />
              200
            </button>
          </div>
          <p className="ques">?</p>
        </div>
        <div className="ele" onClick={() => onAutoTap()}>
          <div className="ele-box">
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.7891 9.13934C19.6235 10.5 18.2347 12.0834 17.5 12.5L12.0973 18.2434L12.0959 18.2449C11.8861 18.4547 11.6871 18.6537 11.5183 18.8216C11.0849 19.2533 10.3848 19.2533 9.9505 18.8216C7.80114 16.6815 1.96485 10.8705 1.84367 10.7518C-0.614556 8.29188 -0.614556 4.302 1.84367 1.84462C4.30021 -0.610234 8.2741 -0.613601 10.734 1.82695C13.1956 -0.613601 17.1703 -0.609393 19.6235 1.84462C21.5987 3.81726 21.9849 6.77622 20.7891 9.13934Z"
                fill="#FFD527"
              />
            </svg>
          </div>
          <div>
            <p>Autotap</p>
            <button disabled={autotap} className="buy-btn">
              <img width="17px" height="16px" src={imgGif} alt="" />
              500
            </button>
          </div>
          <p className="ques">?</p>
        </div>
      </div>
    </div>
  );
};

export default Boosters;
