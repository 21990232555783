import React, { useEffect, useState } from "react";
import orangeImage from "../img/orange.png";
import yellowBgImage from "../img/yellow_bg.png";
import duckGif1 from "../img/1 (1).gif";
import duckGif2 from "../img/1 (2).gif";
import duckGif3 from "../img/1 (3).gif";
import duckGif4 from "../img/1 (4).gif";
import duckGif5 from "../img/1 (5).gif";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const Ducks = () => {
  const level = useSelector((state) => state.currentUser.level);
  const navigate = useNavigate();

  useEffect(() => {
    if (window.Telegram) {
      const tg = window.Telegram.WebApp;

      tg.ready();

      tg.BackButton.show();
      tg.BackButton.onClick(() => {
        tg.BackButton.hide();
        navigate('/');
      });
    }
  }, [navigate]);

  return (
    <div className="game">
      <div className="balance">
        <div>
          <p className="balance-text">Evalution</p>
          <div>
            <span className="balance-score">Duck</span>
          </div>
        </div>
        <img src={orangeImage} alt="" className="img_1" />
        <img src={yellowBgImage} alt="" />
      </div>
      <div className="ducks">
        <div className="duck">
          <p>Lvl 1</p>
          <img width="215px" height="190px" src={duckGif1} alt="" />
        </div>
        {[
          { level: 2, src: duckGif2 },
          { level: 3, src: duckGif3 },
          { level: 4, src: duckGif4 },
          { level: 5, src: duckGif5 },
        ].map((duck, index) => (
          <div className="duck" key={duck.level}>
            <div
              className="blr"
              style={{ filter: duck.level > level ? "blur(3px)" : "blur(0px)" }}
            >
              <p>Lvl {duck.level}</p>
              <img width="215px" height="190px" src={duck.src} alt="" />
            </div>
            {(duck.level > level)&& (
              <svg
                className="lock_"
                width="32"
                height="35"
                viewBox="0 0 32 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 22.0833V25.75M6.625 13.8861C7.50891 13.8333 8.59861 13.8333 10 13.8333H22C23.4014 13.8333 24.4911 13.8333 25.375 13.8861M6.625 13.8861C5.5219 13.952 4.73929 14.1001 4.07131 14.4328C3.01289 14.9601 2.15238 15.8014 1.61309 16.8363C1 18.013 1 19.553 1 22.6333V25.2C1 28.2804 1 29.8204 1.61309 30.997C2.15238 32.0319 3.01289 32.8732 4.07131 33.4005C5.27455 34 6.8497 34 10 34H22C25.1504 34 26.7254 34 27.9287 33.4005C28.9872 32.8732 29.8476 32.0319 30.3869 30.997C31 29.8204 31 28.2804 31 25.2V22.6333C31 19.553 31 18.013 30.3869 16.8363C29.8476 15.8014 28.9872 14.9601 27.9287 14.4328C27.2607 14.1001 26.4781 13.952 25.375 13.8861M6.625 13.8861V10.1667C6.625 5.10406 10.8223 1 16 1C21.1776 1 25.375 5.10406 25.375 10.1667V13.8861"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ducks;
