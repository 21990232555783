import React from "react";
import duckImage from "../img/1 (7).gif";
import orangeImage from "../img/orange.png";
import yellowBgImage from "../img/yellow_bg.png";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

const Coins = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const balance = useSelector((state) => state.currentUser.balance);

  const flX = useSelector((state) => state.currentUser.followedX);
  const flTg = useSelector((state) => state.currentUser.followedTg);
  useEffect(() => {
    if (window.Telegram) {
      const tg = window.Telegram.WebApp;

      tg.ready();

      tg.BackButton.show();
      tg.BackButton.onClick(() => {
        tg.BackButton.hide();
        navigate("/");
      });
    }
  }, [navigate]);

  return (
    <div className="game">
      <div className="balance">
        <div>
          <p className="balance-text">Earn more</p>
          <div>
            <span className="balance-score">Coins</span>
          </div>
        </div>
        <img src={orangeImage} alt="" className="img_1" />
        <img src={yellowBgImage} alt="" />
      </div>
      <p className="page-name">Specials</p>
      <div className="elements">
        <a href="https://facebook.com" target="_blank" onClick={
          async () => {
            if (!flTg){
              const tg = window.Telegram.WebApp;
              try {
                const res = await fetch('https://duckducktap.xyz:3005/api/user/complete-task', {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ telegramId: String(tg.initDataUnsafe.user.id), task: "telegram" })
                })
              }
              catch (e) {
                console.log(e)
              }
              dispatch({
                type: "telegram",
                payload: {
                  balance: balance + 500,
                  followedTg: true,
                }
              })
            }
          }
        }>
          <div className="ele">
            <div className="ele-box">
              <svg
                width="26"
                height="21"
                viewBox="0 0 26 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.7948 0.18545C20.3648 1.57387 5.65756 7.52778 1.59394 9.15051C-1.1313 10.1899 0.464091 11.1645 0.464091 11.1645C0.464091 11.1645 2.79042 11.944 4.78473 12.5286C6.77876 13.1133 7.84227 12.4638 7.84227 12.4638L17.2145 6.2922C20.538 4.08343 19.7404 5.90232 18.9426 6.68208C17.2145 8.37129 14.3563 11.0345 11.9633 13.1784C10.8998 14.0879 11.4314 14.8674 11.897 15.2573C13.625 16.6865 18.3446 19.61 18.6103 19.8048C20.0144 20.7763 22.7765 22.1748 23.1966 19.2201L24.8583 9.02082C25.3902 5.57783 25.9218 2.3945 25.9882 1.48505C26.1878 -0.723997 23.7948 0.18545 23.7948 0.18545Z"
                  fill="#1B92D1"
                />
              </svg>
            </div>
            <div>
              <p>Join our TG group</p>
              <button className="buy-btn" disabled={flTg}>
                <img width="17px" height="16px" src={duckImage} alt="" />
                500
              </button>
            </div>
          </div>
        </a>
        <a href="#" target="_blank" className="x_" onClick={
          async () => {
            const tg = window.Telegram.WebApp;
            if (!flX){
              try {
                const res = await fetch('https://duckducktap.xyz:3005/api/user/complete-task', {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ telegramId: String(tg.initDataUnsafe.user.id), task: "x" })
                })
              }
              catch (e) {
                console.log(e)
              }
              dispatch({
                type: "x",
                payload: {
                  balance: balance + 500,
                  followedX: true,
                }
              })
            }
          }
        }>
          <div className="ele">
            <div className="ele-box">
              <svg
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.7642 0.4375H22.3479L14.5186 9.38583L23.7291 21.5625H16.5174L10.8689 14.1774L4.40573 21.5625H0.819898L9.19407 11.9913L0.358398 0.4375H7.75323L12.859 7.18775L18.7642 0.4375ZM17.5065 19.4175H19.4922L6.67423 2.46983H4.54332L17.5065 19.4175Z"
                  fill="white"
                />
              </svg>
            </div>
            <div>
              <p>Follow us on X</p>
              <button className="buy-btn" disabled={flX}>
                <img width="17px" height="16px" src={duckImage} alt="" />
                500
              </button>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Coins;
