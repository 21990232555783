import React, { useState } from "react";
import orangeImg from "../img/orange.png";
import yellowBgImg from "../img/yellow_bg.png";
import inviteFriendImg from "../img/1 (7).gif";
import friendPremiumImg from "../img/1 (6).gif";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Friends = ({ telegramId, refCode }) => {
  const navigate = useNavigate();
  const [friends, setFriends] = useState([]);
  const [copied, setCopied] = useState(false)
  const link = `https://t.me/sup_duck_bot?start=${telegramId}`

  useEffect(() => {
    if (window.Telegram) {
      const tg = window.Telegram.WebApp;
      tg.ready();

      tg.BackButton.show();
      tg.BackButton.onClick(() => {
        tg.BackButton.hide();
        navigate("/");
      });
    }
  }, [navigate]);

  useEffect(() => {
    const getFriends = async () => {
      try {
        const response = await fetch(
          `https://duckducktap.xyz:3005/api/user/get-referrals`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ referral: telegramId }),
          }
        );
        const data = await response.json();
        console.log(data);
        setFriends(data);
      } catch (error) {
        console.log(error);
      }
    };
    getFriends();
  }, []);

  return (
    <div className="game">
      <div className="balance">
        <div>
          <p className="balance-text">
            Your
            <div>
              <span className="balance-score">Friends</span>
            </div>
          </p>
        </div>
        <img src={orangeImg} alt="" className="img_1" />
        <img src={yellowBgImg} alt="" />
      </div>
      <p className="page-name" style={{ color: "rgba(255, 213, 39, 1)" }}>
        Invite friend to get bonuses
      </p>
      <div
        className="elements friends"
        style={{ gridTemplateRows: "88px 88px" }}
      >
        <div className="ele">
          <div className="ele-box">
            <img src={inviteFriendImg} alt="" width="51px" height="47px" />
          </div>
          <div>
            <p>Invite friend</p>
            <button className="buy-btn">
              <img width="17px" height="16px" src={inviteFriendImg} alt="" />
              500
            </button>
          </div>
        </div>
        <div className="ele">
          <div className="ele-box">
            <img src={friendPremiumImg} alt="" width="59px" height="49px" />
          </div>
          <div>
            <p>Friend telegram premium</p>
            <button className="buy-btn">
              <img width="17px" height="16px" src={inviteFriendImg} alt="" />
              500
            </button>
          </div>
        </div>
      </div>
      <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
        <button className="inv" style={copied ? {opacity: "70%"} : null}>Invite a friend</button>
      </CopyToClipboard>
      <div className="bl">
        <p className="page-name">Friends list</p>
        <div></div>
      </div>
      {friends.length ? (
        <div className="driends">
          {
            friends.map(el => {
              return (
                <div className="dr_panel" key={el.id}>
                  <div className="dr_img" style={{backgroundImage: `url('1 (${el.level}).gif')`}}></div>
                  <div>
                    <p>{el.telegramId}</p>
                    <span className="dr_data">Lvl {el.level} {el.balance}</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      ) : (
        <div className="friends-list">
          <span>No friend yet</span>
        </div>
      )}
    </div>
  );
};

export default Friends;
