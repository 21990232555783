import { createStore } from "redux";

const store = createStore(
  (state, action) => {
    if (action.type === "add-data") {
      return {
        ...state,
        currentUser: {
          balance: action.payload.balance,
          level: action.payload.level,
          duck: action.payload.duck,
          followedX: action.payload.followedX,
          followedTg: action.payload.followedTg,
          refCode: action.payload.refCode
        },
      };
    }
    if (action.type === "telegram") {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          balance: action.payload.balance,
          followedTg: action.payload.followedTg,
        },
      };
    }
    if (action.type === "x") {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          balance: action.payload.balance,
          followedX: action.payload.followedX,
        },
      };
    }
    if (action.type === "edit-balance") {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          balance: action.payload.balance,
        },
      };
    }
    if (action.type === "edit-level") {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          level: action.payload.level,
        },
      };
    }
    if (action.type === "hardtap") {
      return {
        ...state,
        boosters: {
          ...state.boosters,
          hardtap: action.payload.hardtap,
        },
      };
    }
    if (action.type === "speedtap") {
      return {
        ...state,
        boosters: {
          ...state.boosters,
          speedtap: action.payload.speedtap,
        },
      };
    }
    if (action.type === "autotap") {
      return {
        ...state,
        boosters: {
          ...state.boosters,
          autotap: action.payload.autotap,
        },
      };
    }
    return state;
  },
  {
    currentUser: {
      balance: 0,
      duck: "1 (1).gif",
      level: 1,
      followedTg: false,
      followedX: false,
      refCode: null,
    },
    boosters: {
      hardtap: false,
      speedtap: false,
      autotap: false,
    },
  }
);
export default store;
